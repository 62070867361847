<template>
  <div>
    <dialog-form-list
      :config="config"
      :formData="formData"
      @cancel="cancel"
      @confirm="confirm"
      @openDialog="openDialog"
    >
    </dialog-form-list>
    <!-- 弹出表格 -->
    <dialog-table-list
      width="1200px"
      v-if="showDialogTableList"
      ref="tableList"
      :title="dialogTitle"
      :url="dialogUrl"
      :defaultParams="dialogDefaultParams"
      :columns="dialogColumns"
      :formData="dialogFormData"
      :config="dialogConfig"
      v-model="dialogTableRow"
      @cancel="dialogCancel"
      @confirm="dialogConfirm"
      @search="dialogSearch"
      :multiple="false"
    >
    </dialog-table-list>
  </div>
</template>

<script>

import {arithmeticAdd, arithmeticUpdate, dataCenterFindList} from "@/api";

export default {
  props: {
    tableRow: {
      // 当前选中的表格行数据
      type: Object,
      default: () => null,
    },
  },
  data() {
    const self = this
    return {
      cmd: this.$store.state.cmd,
      formData: {},
      config: [
        // lang:名称
        {
          label: this.$t('recognitionAlgorithmManage.name'),
          prop: 'name',
          hidden: false,
          rules: {
            noNull: true,
          }
        },
        // lang:类型
        {
          tag: 'el-select',
          label: this.$t('recognitionAlgorithmManage.type'),
          prop: 'recognitionAlgorithmType',
          hidden: false,
          rules: {
            noNull: true,
          },
          tagProps: {
            options: this.$dict.algorithmType(),
            // props: {
            //   label: 'value',
            //   value: 'id',
            // },
          },
          tagEvents: {
            change(val) {
              if (val === 'API') {
                self.config[2].hidden = false
                self.config[2].rules.noNull = true
              } else {
                self.config[2].hidden = true
                self.config[2].rules.noNull = false
              }
            },
          }
        },
        // lang:外部API
        {
          tag: 'dialog-define',
          label: this.$t('recognitionAlgorithmManage.externalApiName'),
          prop: 'externalApiFieldId',
          hidden: true,
          rules: {
            noNull: false,
          },
          tagEvents: {
            // 显示名称
            showName(data) {
              return data && data.name
            },
          },
        }
      ],
      enableObj: this.$util.listToObj(this.$dict.enable()),
      // 弹出表格
      showDialogTableList: false,
      dialogTitle: '',
      dialogUrl: '',
      dialogDefaultParams: {},
      dialogColumns: [],
      dialogFormData: {},
      dialogConfig: {},
      dialogTableRow: [],
      workFlowStepTemplateTypeEnum: [],
      workFlowElementTypeEnum: [],
      workFlowElementFormTypeEnum: [],
      fileSrc: null,
      fileId: null,
    }
  },
  async mounted() {
    this.setDefaultValue()
  },
  methods: {
    // 设置默认值
    setDefaultValue() {
      console.log(this.formData)
      this.$nextTick(() => {
        //默认值
        if (this.cmd !== 'add') {
          this.$util.setDefaultValue(this.formData, this.tableRow)
          if(this.tableRow.externalApiField) {
            this.config[2].hidden = false
            this.config[2].rules.noNull = true
            this.formData.externalApiFieldId = this.tableRow.externalApiField
          } else {
            this.config[2].hidden = true
            this.config[2].rules.noNull = false
          }
        }
      })
    },
    // 取消
    cancel() {
      this.$emit('closePage', 'cancel')
    },
    // 确定
    confirm() {
      console.log('confirm', this.formData)
      console.log('cmd', this.cmd)
      const formData = this.$util.copyData(this.formData)
      formData.externalApiFieldId = formData.externalApiFieldId.id
      switch (this.cmd) {
        case 'add':
          this.addMethod(formData)
          break
        case 'update':
          this.editMethod(formData)
          break
        default: // do something
      }
    },
    // 新增
    addMethod(formData) {
      console.log(formData)
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.arithmeticAdd,
        data: formData,
      }).then(() => {
        // lang:新增成功
        this.$element.showMsg(this.$t('base.addSuccess'), 'success')
        this.$forceUpdate()
        this.$emit('closePage', 'add')
      })
    },
    editMethod(formData) {
      formData.id = this.tableRow.id
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.arithmeticUpdate,
        data: formData,
      }).then(() => {
        // lang:编辑成功
        this.$element.showMsg(this.$t('base.editSuccess'), 'success')
        this.$forceUpdate()
        this.$emit('closePage', 'edit')
      })
    },

    // 打开表格弹框
    openDialog(item) {
      this.targetProp = item.prop
      this.dialogTableRow = this.formData[item.prop] || null
      // 选择设备
      this.dialogTitle = this.$t('recognitionAlgorithmManage.selectExternalApi')
      this.dialogUrl =  '/' + this.$util.getLocalStorage('apiprefix') + this.$api.dataCenterFindList
      this.dialogDefaultParams = {
        companyUid: this.$store.getters.company.uid,
        scope: 'CHANNEL_LINE',
        searchAll: 'YES'
      }
      this.dialogColumns = [
        // lang:外部API名称
        {
          label: this.$t('recognitionAlgorithmManage.externalApiName'),
          prop: 'name',
        },
        // lang:uid
        {
          label: 'UID',
          prop: 'uid',
        },
      ]
      this.dialogConfig = [
      ]
      this.showDialogTableList = true
    },
    // 确认表格弹框
    dialogConfirm(row) {
      this.formData[this.targetProp] = row
      this.showDialogTableList = false
    },
    // 关闭表格弹框
    dialogCancel() {
      this.showDialogTableList = false
    },
    // 表格弹框搜索
    dialogSearch() {
      const dialogFormData = this.$util.copyData(this.dialogFormData)
      this.$refs.dialogTableList.searchList(dialogFormData)
    }
  },
}
</script>
<style lang="scss" scoped>
@import '@/style/theme.scss';
.upload-no-img {
  width: 200px;
  height: 200px;
  font-size: 36px;
  line-height: 200px;
  text-align: center;
  border: 1px dashed #cccccc;
  cursor: pointer;
  border-radius: 4px;
}
:hover.upload-no-img {
  border: 1px dashed blue;
  color: blue;
}
.upload-has-img {
  width: 200px;
  height: 200px;
  line-height: 200px;
  cursor: pointer;
  border-radius: 4px;
  object-fit: contain;
  background: black;
}
.element-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 5px;
  &:last-child {
    margin-bottom: 0;
  }
  .field {
    width: 78%;
    border: 5px solid #eeeeee;
    background: #eeeeee;
    margin-right: 5px;
    .field-item {
      display: flex;
      flex-direction: row;
      margin-bottom: 5px;
      .el-input:first-child {
        margin-right: 5px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .opt {
    i {
      margin-right: 10px;
      cursor: pointer;
      color: $--color-primary;

      &:hover {
        font-weight: bold;
      }
    }
  }
}
</style>
